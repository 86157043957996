<template>
  <page-structure title="about" :loading="loading">
    <b-container>
      <div class="row">
        <div class="col-md-4 image animate-box fadeInUp animated">
          <div class="img">
            <img
              class="about-img"
              :src="require('@/assets/images/about/about.jpg')"
              alt=""
            />
          </div>
        </div>
        <div
          class="col-md-8 about-text animate-box fadeInUp animated"
          data-animate-effect="fadeInUp"
        >
          <!-- About -->
          <div class="row">
            <div class="col-md-12">
              <!-- <h3>Hi my name is Andrei</h3> -->
              <!-- Show all paragarafs of about information -->
              <p
                v-for="(paragraf, idx) in paragrafs"
                :key="idx"
                v-text="paragraf"
              ></p>
              <p>
                I mentioned that art has definitely settled in my soul, to
                discover my other passions, please click the button below
              </p>
              <div class="section-tools animate-box fadeInUp animated">
                <router-link to="/hobbies" class="next-link"
                  >Other hobbies</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </page-structure>
</template>

<script>
export default {
  name: "about",
  data: () => {
    return {
      loading: false,
      paragrafs: [
        "Hi, I’m Andrei Moldovan and I love Art. I am a Moldovan artist painting Alaskan stuff and not only.",
        "Ever since I can remember, I have been passionate about the creation of Art. I always wanted to become an architect, and since childhood I had a tangent with linear drawing and geometry.",
        "My journey in Art began at the end of 2017, my main concern was to find my vocation. I was 28 years old, with studies in the field of environmental engineering, jobs in agriculture, construction, and fish processing, none of which piqued my interest. Now I am grateful to those who refused me then to job interviews, which redirected me to the beginning of my career in the field of Art. It all started with a set of oil colors received as a Christmas gift, from someone dear to me, who suggested to try oil painting.  I was initially skeptical, until  I layed down the first brush strokes on that paper! Yes, it was a paper, as I didn’t know about canvases back then. Finding  out  the tendency of the colors to mix together, and how malleable the oil is, amazed me! Oil painting has since become a part of me, trying day by day to become a better artist than I was yesterday.",
        "I live in Moldova, where I spend most of the year painting! In the summer I spend my time in Alaska, working in the field of fish processing. Alaska, and its landscapes had influenced my artistic style - Realism. I've always been fascinated by the sunsets and sunrises in Alaska. The long- rainy days from there, the fog and the swampy landscapes. Lately, I've been focusing on reproducing those wild places that I find simply fascinating. Also, here I had my first exhibition in 2020, where I was able to exhibit some works to a small audience. I was fascinated by the fact that people found themselves in my works. I feel blessed to be able to spend a few months of the year, at this end of the world place, or how it's called “The last frontier”! It's where I can study nature live to discover the genesis of landscaping.",
        "It is difficult to paint what you see but is even harder to express through painting what you see. I can’t fully attribute myself to which camp I’m at the moment, but one thing is certain I will try to avoid as much as possible the modern art, which has become more conceptual, and the talent and aesthetics are lacking",
        "I want my paintings to reflect something personal, to be remunerated for what I do, with what I know. The lack of studies in art was not an obstacle for me, on the contrary, I ventured into the independent study of painting techniques, the Renaissance. With each painting executed I try to better understand how the laws of nature work, especially the light, which unfortunately it cannot be reproduced, but it must be represented. My goal is to reach that level of classical realism, probably romanticism, in which a painting to look like a painting, not photography, so the landscape to be like a visual dialect to the public, so that everyone can understand it.",
        "If I could give any advice for all those who have a passion in art and not only - it is first of all to discover their vocation. To avoid negative people, who will always tell you that you are not good enough. Listen to your inner voice, do not be afraid of failure, and you are forced to fail in order to perfect yourself. Picasso once said, “every child is an artist, the problem is how to remain an artist when you grow up”.",
        "In order to find out more about me and my other hobbies, my techniques, please click the button below.",
      ],
    };
  },
  mounted() {
    this.$emit("setsocial", true);
  },
  metaInfo: {
    title: "About",
    titleTemplate: "%s - moldoVAN",
    htmlAttrs: {
      lang: "en",
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/aboutPage";
</style>
